
export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'fa-solid fa-house-laptop',
		subMenu: null,
	},
};

export const generalPages = {
	general: {
		id: 'general',
		text: 'General',
	},
	companies: {
		id: 'companies',
		text: 'Companies',
		path: '/companies-configuration',
		icon: 'fa-regular fa-folder-open',
	},
	buildings: {
		id: 'buildings',
		text: 'Buildings',
		path: '/buildings-configuration',
		icon: 'fa-solid fa-building-circle-arrow-right'
	},
	vatRegister: {
		id: 'vatRegister',
		text: 'VAT Register',
		path: '/vat-register',
		icon: 'fa-regular fa-business-time',
	}
}

export const appConfigs = {
	general: {
		id: 'app-settings',
		text: 'App Settings',
	},
	appSettings: {
		id: 'appSettingsFlow',
		text: 'Settings',
		path: '/app-settings',
		icon: 'fa-solid fa-gear',
	},
	admins: {
		id: 'admins',
		text: 'App users',
		path: '/app-users',
		icon: 'fa-solid fa-users-gear'
	},
	apiConnections: {
		id: 'third-party-connections',
		text: 'Third party API',
		path: '/app-third-party-connections',
		icon: 'fa-brands fa-dropbox'
	},
};

export const administrationPages = {
	administration: {
		id: 'administration',
		text: 'Administration',
	},
	invoices: {
		id: 'invoices',
		text: 'Finance',
		path: '/invoices',
		icon: 'fa-solid fa-coins',
	},
	tenants: {
		id: 'tenants',
		text: 'Tenants',
		path: '/tenants',
		icon: 'fa-solid fa-building-user'
	},
	guests: {
		id: 'guests',
		text: 'Guests',
		path: '/guests',
		icon: 'fa-solid fa-people-roof'
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: '/utilities',
		icon: 'fa-solid fa-leaf'
	},
	inventory: {
		id: 'inventory',
		text: 'Maintenance',
		path: '/inventory',
		icon: 'fa-solid fa-screwdriver-wrench',
		subMenu: {
			tickets: {
				id: 'tickets',
				text: 'Tickets',
				path: '/maintenance/tickets',
				icon: 'fa-solid fa-list-check',
			},
			inventoryObjects: {
				id: 'inventoryObjects',
				text: 'Inventory objects',
				path: '/inventory/objects',
				icon: 'fa-solid fa-sitemap'
			},
		}

	},
	rentalRequests: {
		id: 'rentalRequest',
		text: 'Rentals',
		path: '/rental-requests',
		icon: 'fa-solid fa-door-open',
	},
}
export const demoPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	resetPassword: {
		id: 'resetPassword',
		text: 'Reset password',
		path: 'auth-pages/reset-password',
		icon: 'Login',
	},
	verifyAccount: {
		id: 'verifyAccount',
		text: 'Verify Account',
		path: 'auth-pages/verify-account',
		icon: 'Login',
	},

	requestRental: {
		id: 'requestRental',
		text: 'Rental Request',
		path: 'auth-pages/rental-request',
		icon: '',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	invoice: {
		id: 'publicInvoice',
		text: 'Invoice',
		path: 'auth-pages/invoice',
		icon: 'ReportGmailerrorred',
	}
};
export const layoutMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};
