import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import useDarkMode from "../../../hooks/useDarkMode";
import classNames from "classnames";

const DefaultFooter = () => {

    const {darkModeStatus} = useDarkMode();
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <>
            {user?.token && (
                <Footer>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col'>
                                <span className={`${darkModeStatus ? 'text-warning' : 'text-info'}`} style={{fontWeight: 600, fontSize: 11}}>{`Copyright © ${new Date().getFullYear()} - Version 1.0.9`}</span>
                            </div>
                            <div className='col-auto'>
                                <a
                                    href='/'
                                    className={classNames('text-decoration-none', {
                                        'link-dark': !darkModeStatus,
                                        'link-light': darkModeStatus,
                                    })}>
                                    <small className='fw-bold'>We rent - Management APP</small>
                                </a>
                            </div>
                        </div>
                    </div>
                </Footer>
            )}
        </>
    );
};

export default DefaultFooter;
