import { configureStore } from '@reduxjs/toolkit';
import companySlice from './slices/company';
import userSlice from './slices/user';
import invitationSlice from './slices/invitation';
import rentalRequestsSlice from './slices/rentalRequest';
import apartmentSlice from './slices/apartment';
import utilitySlice from './slices/utility';
import buildingSlice from './slices/building';
import inventorySlice from './slices/inventory';
import contractSlice from './slices/contract';
import invoiceSlice from './slices/invoice';
import receiptSlice from './slices/receipt';
import ticketServiceItemSlice from './slices/ticketServiceItem';
import ticketSlice from './slices/ticket';
import appSettingsSlice from './slices/appSettings';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

const persistConfig = {
  key: "root",
  storage
};

const persistedReducerCompany = persistReducer(persistConfig, companySlice);
const persistedReducerUser = persistReducer(persistConfig, userSlice);
const persistedReducerInvitation = persistReducer(persistConfig, invitationSlice);
const persistedReducerRentalRequests = persistReducer(persistConfig, rentalRequestsSlice);
const persistedReducerApartment = persistReducer(persistConfig, apartmentSlice);
const persistedReducerUtility = persistReducer(persistConfig, utilitySlice);
const persistedReducerBuilding = persistReducer(persistConfig, buildingSlice);
const persistedReducerInventory = persistReducer(persistConfig, inventorySlice);
const persistedReducerContract = persistReducer(persistConfig, contractSlice);
const persistedReducerInvoice = persistReducer(persistConfig, invoiceSlice);
const persistedReducerReceipt = persistReducer(persistConfig, receiptSlice);
const persistedReducerTicketServiceItem = persistReducer(persistConfig, ticketServiceItemSlice);
const persistedReducerTicket = persistReducer(persistConfig, ticketSlice);
const persistedReducerAppSettings = persistReducer(persistConfig, appSettingsSlice);

const store = configureStore({
  reducer: {
    company: persistedReducerCompany,
    user: persistedReducerUser,
    invitation: persistedReducerInvitation,
    rentalRequest: persistedReducerRentalRequests,
    apartment: persistedReducerApartment,
    utility: persistedReducerUtility,
    building: persistedReducerBuilding,
    inventory: persistedReducerInventory,
    contract: persistedReducerContract,
    invoice: persistedReducerInvoice,
    receipt: persistedReducerReceipt,
    ticketServiceItem: persistedReducerTicketServiceItem,
    ticket: persistedReducerTicket,
    appSettings: persistedReducerAppSettings,
  },
  middleware: [thunk]
});

const persistor = persistStore(store);
const { dispatch } = store;
const useSelector = useAppSelector;
export type RootState = ReturnType<typeof store.getState>
const useDispatch = () => useAppDispatch();
export { store, persistor, dispatch, useSelector, useDispatch };
