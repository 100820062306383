import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AppSettingsState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: AppSettingsState = {
    isAdded: false,
    isUpdated: false,
}

export const AppSettingsSlice = createSlice({
    name: "appSettings",
    initialState,
    reducers: {
        addAppSettings: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateAppSettings: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addAppSettings, updateAppSettings} = AppSettingsSlice.actions;
export default AppSettingsSlice.reducer;
